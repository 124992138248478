import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PageIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const pages = data.allMarkdownRemark.nodes

  if (pages.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO 
          title={siteTitle} 
        />
        <p>
          No pages found. Add markdown pages to "content/" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} >
      <section className="hero">
        <div className="blur">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <div className="hero-content text-center">
                  <div dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.custom_excerpt}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SEO />
      <section className="container">
        <div className="clients">
            <div className="row">
              <div className="col-lg-12 text-center">
                <p>Na stronach internetowych naszych partnerów mogą kupić Państwo gotowe projekty, które dostosujemy do Waszych potrzeb:</p>
                <ul className="list-unstyled">
                  {data.partners.edges.map(({ node }) => {
                    return (
                      <li>
                          <Link to={node.url} itemProp="url">
                            <img width="110" src={node.logo} title="name" alt="" />
                          </Link>
                      </li>
                      )
                  })}
                </ul>
              </div>
            </div>
        </div>
      </section>
    </Layout>
  )
}

export default PageIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        custom_excerpt
      }
    }
    partners:allPartnersJson {
      edges {
        node {
          logo
          name
          url
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
